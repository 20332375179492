import { Box, Button, Container, IconButton, Paper, styled, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import showNotification from '../../commons/helpers/showNotification';
import yup from '../../commons/validators/customYup';
import { FormTextField } from '../../components/forms/FormikFields';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import FeatureService from '../../services/FeatureService';
import { getPriorityIdByParam, getStatusIdByParam } from './featureListHelpers';
import FeatureMenusFormSelect from './FeatureMenusFormSelect';
import FeaturePriorityFormSelect from './FeaturePrioritysFormSelect';
import FeatureStatusFormSelect from './FeatureStatusFormSelect';

const PaperStyled = styled(Paper)(({ theme }) => ({ padding: theme.spacing(2) }));

const schema = yup.object().shape({
  name: yup.string().min(2).max(50).required(),
  description: yup.string().max(100).notRequired(),
  menu: yup.string().required(),
  priority: yup.number().required(),
  status: yup.number().required(),
});

const DEFAULT_VALUES = {
  name: '',
  description: '',
  menu: 'NO_MENU',
  priority: 0,
  status: 0,
};

// COMPONENT
function FeatureFormPage() {
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      FeatureService.getById(id).then((resp) => {
        setInitialValues({
          id: resp.id,
          name: resp.name,
          description: resp.description ?? '',
          menu: resp.menu ? resp.menu : 'NO_MENU',
          priority: getPriorityIdByParam(resp.priority),
          status: getStatusIdByParam(resp.status)
        });
      });
    }
  }, [id]);



  const myHandleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      await FeatureService.save(values);
      showNotification('success', values.id ? 'Atualizado com successo.' : 'Criado com successo.');
      navigate(-1);
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <FontAwesomeIconStatus icon="arrow-left" status="primary" />
        </IconButton>
        <Typography variant="h5">{id ? 'Atualizando' : 'Criando'} Feature</Typography>
      </Box>

      <PaperStyled>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, isValid}) => (
            <Form>
              <FormTextField fullWidth label="Nome" name="name" />
              <FormTextField fullWidth label="Descrição" name="description" />
              <FeatureMenusFormSelect />
              <FeaturePriorityFormSelect />
              <FeatureStatusFormSelect />
              <Button variant="contained" type="submit" color="primary" disabled={!dirty || isSubmitting || !isValid}>
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
      </PaperStyled>
    </Container>
  );
}

export default FeatureFormPage;
