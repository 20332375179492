import React, { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import { Form, Formik } from "formik";
import showNotification from "../../../commons/helpers/showNotification";
import NumberUtils from "../../../commons/utils/numberUtils";
import yup from "../../../commons/validators/customYup";
import SuperUserService from "../../../services/SuperUserService";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

const schema = yup.object().shape({
  discountId: yup.number().required(),
});

const DEFAULT_INIT_VALUES = {
  discountId: "",
};

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    color: "rgb(250, 250, 253)",
    backgroundColor: theme.palette.primary.main,
  },
}));

const ToggleTextStyled = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: "capitalize",
}));

// COMPONENT
function AsaasUpdatePaymentModal({
  selectedCompany,
  payment,
  open,
  onClose,
  onSave,
}) {
  const [asaasDiscount, setAsaasDiscount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // added hardcode temporarily
  const fixedDiscount = useMemo(() => {
    return [
      {
        id: 999,
        name: "Desconto Fixo Mensal Vitalício",
        discountPrice: 18.9,
        maxNumberOfUsers: 3,
        periodType: "MONTHLY",
      },
    ];
  }, []);

  useEffect(() => {
    if (open) {
      setAsaasDiscount([]);
      setIsLoading(true);
      if (payment.status === "ACTIVE") {
        if (payment.periodType === "MONTHLY") {
          setAsaasDiscount(fixedDiscount);
        } else {
          showNotification(
            "warning",
            "Sem descontos cadastrados para essa recorrencia"
          );
        }
        setIsLoading(false);
      } else {
        SuperUserService.getAsaasDiscount(selectedCompany.id)
          .then((asaasDiscounts) => {
            setAsaasDiscount(asaasDiscounts);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [open, selectedCompany, payment, fixedDiscount]);

  const myHandleSubmit = (values, actions) => {
    const answer = window.confirm(
      "Você tem certeza que deseja alterar o valor desta cobrança no Asaas?"
    );
    if (answer) {
      setIsLoading(true);
      SuperUserService.upgradeAsaasPayment({
        paymentId: payment.id,
        selectedCompanyId: selectedCompany.id,
        discountId: values.discountId,
      })
        .then(() => {
          showNotification("success", "Atualizado com successo.");
          onClose();
          onSave();
        })
        .finally(() => {
          actions.setSubmitting(false);
          setIsLoading(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="span" variant="h5">
            Atualizar valor da cobrança
          </Typography>
        </Box>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={DEFAULT_INIT_VALUES}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                {isLoading && <LinearProgress size={24} />}
                {!isLoading && asaasDiscount && (
                  <ToggleButtonGroupStyled
                    size="small"
                    value={`${values.planId}`}
                    onChange={(evt, newValue) => {
                      if (newValue != null) {
                        setFieldValue("discountId", newValue);
                        setFieldValue("planId", newValue);
                      }
                    }}
                    exclusive
                  >
                    {asaasDiscount.map((discount) => (
                      <ToggleButtonStyled
                        key={discount.id}
                        value={`${discount.id}`}
                      >
                        <ToggleTextStyled>
                          {discount.name} -{" "}
                          {NumberUtils.toCurrency(discount.discountPrice, 2)}
                        </ToggleTextStyled>
                      </ToggleButtonStyled>
                    ))}
                  </ToggleButtonGroupStyled>
                )}
                {!isLoading && asaasDiscount && asaasDiscount.length === 0 && (
                  <Typography variant="h5">
                    Nenhum desconto encontrado
                  </Typography>
                )}
              </Box>

              {!isLoading &&
                asaasDiscount &&
                asaasDiscount.length > 0 &&
                payment.status === "OVERDUE" && (
                  <Alert severity="warning">
                    <b>Atenção:</b> A data de vencimento será alterada
                    automaticamente para hoje.
                  </Alert>
                )}
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AsaasUpdatePaymentModal;
