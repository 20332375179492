import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, LinearProgress, Paper, styled, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import ConfirmationDialog from '../../components/modals/ConfirmationDialog';
import HintText from '../../components/labels/HintText';
import FeatureService from '../../services/FeatureService';
import showNotification from '../../commons/helpers/showNotification';
import FeatureListFilterByStatus from './FeatureListFilterByStatus';
import FeatureTable from './FeatureTable';
import FeaturePreviewModal from './FeaturePreviewModal';
import FeatureAddCompanyEmailModal from './FeatureAddCompanyEmailModal';
import FeatureListFilterByMenu from './FeatureListFilterByMenu';

const PaperStyled = styled(Paper)(({ theme }) => {
  return {
    padding: theme.spacing(2),
  };
});

// COMPONENT
function FeatureListPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState();
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false });
  const [modalFeaturePreview, setModalFeaturePreview] = useState({ open: false });
  const [modalFeatureAddCompany, setModalFeatureAddCompany] = useState({ open: false });

  useEffect(() => {
    async function initData() {
      setIsLoading(true);
      try {
        const resp = await FeatureService.find(selectedStatusId, selectedMenu);
        setFeatures(resp);
      } finally {
        setIsLoading(false);
      }
    }

    initData();
  }, [selectedStatusId, selectedMenu]);

  const handleEdit = (feature) => {
    navigate(`${feature.id}`);
  };

  const handleDelete = (targetObject) => {
    setModalConfirmDelete({
      open: true,
      targetObject,
    });
  };

  const handlePreview = (targetObject) => {
    setModalFeaturePreview({
      open: true,
      targetObject,
    });
  };

  const handleAddCompany = (targetObject) => {
    setModalFeatureAddCompany({
      open: true,
      targetObject,
    });
  };

  const handleDeleteConfirmation = async () => {
    setIsLoading(true);
    try {
      await FeatureService.delete(modalConfirmDelete.targetObject);
      setFeatures(features.filter((data) => data.id !== modalConfirmDelete.targetObject.id));
      showNotification('success', 'Deletado com sucesso');
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCompanyEmail = async () => {
    setModalFeatureAddCompany((prev) => ({ ...prev, open: false }));
    try {
      setIsLoading(true);
      const resp = await FeatureService.find(selectedStatusId, selectedMenu);
      setFeatures(resp);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* MODAL */}
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />

      <FeaturePreviewModal
        featureForm={modalFeaturePreview.targetObject}
        open={modalFeaturePreview.open}
        handleEdit={handleEdit}
        onClose={() => {
          setModalFeaturePreview((prev) => ({ ...prev, open: false }));
        }}
        onUpdateCompanyCount={(updatedCompanyCount, targetObject) => {
          setFeatures(
            features.map((data) =>
              data.id === targetObject.id ? { ...data, countCompanies: updatedCompanyCount } : data,
            ),
          );
        }}
      />
      <FeatureAddCompanyEmailModal
        featureForm={modalFeatureAddCompany.targetObject}
        open={modalFeatureAddCompany.open}
        onAddCompanyEmail={handleAddCompanyEmail}
        onClose={() => {
          setModalFeatureAddCompany((prev) => ({ ...prev, open: false }));
        }}
      />

      {/* CONTENT */}
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="overline">Solicitação de Features</Typography>
            <Button variant="contained" component={Link} to="novo">
              Adicionar Feature
            </Button>
          </Box>

          <Box sx={{ mt: 1 }}>
            {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : <Box sx={{ height: '4px' }} />}
          </Box>

          <PaperStyled square>
            {/* TOOLBAR */}
            <Box display="flex" flexDirection="row">
              <FeatureListFilterByStatus
                selectedStatusId={selectedStatusId}
                setSelectedStatusId={setSelectedStatusId}
              />
              <FeatureListFilterByMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
            </Box>
            {features?.length === 0 ? (
              <Box sx={{ mt: 5, mb: 5 }}>
                <HintText>Nenhum resultado encontrado.</HintText>
              </Box>
            ) : (
              <FeatureTable
                features={features}
                isLoading={isLoading}
                handlePreview={handlePreview}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleAddCompany={handleAddCompany}
              />
            )}
          </PaperStyled>
        </Grid>
      </Grid>
    </>
  );
}

export default FeatureListPage;
