/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import ButtonCopyToClipboard from "../../../components/buttons/ButtonCopyToClipboard";
import SuperUserService from "../../../services/SuperUserService";
import AppleAccountInfoStoreTableModal from "./AppleAccountInfoStoreTableModal";
import LabelStatus from "../../../components/labels/LabelStatus";
import { FontAwesomeIconStatus } from "../../../components/icon/FontAwesomeIconStatus";
import AccountInfoStoreDetailModal from "./AccountInfoStoreDetailModal";

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  justifyContent: "space-between",
}));

const APPLE_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

function getExpirationIntent(expirationIntentId) {
  if (expirationIntentId === 1) {
    return "The customer voluntarily canceled their subscription.";
  }
  if (expirationIntentId === 2) {
    return "Billing error; for example, the customer's payment information was no longer valid";
  }
  if (expirationIntentId === 3) {
    return "The customer did not agree to a recent price increase";
  }
  if (expirationIntentId === 4) {
    return "The product was not available for purchase at the time of renewal";
  }
  if (expirationIntentId === 5) {
    return "Unknown error";
  }
  return "-";
}

// COMPONENT
function AppleAccountInfoModal({ selectedCompany, open, onClose }) {
  const refAppleReceiptHistoryId = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState();
  const [historySubscription, setHistorySubscription] = useState();
  const [value, setValue] = useState(0);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);
  const [refundHistoryDetail, setRefundHistoryDetail] = useState();
  // const [appleSubscriptionDuplicatedModalOpen, setAppleSubscriptionDuplicatedModalOpen] = useState(false);
  // const [duplicateSubscriptions, setDuplicateSubscriptions] = useState();

  const { appleReceiptHistoryId } = selectedCompany;

  useEffect(() => {
    if (
      open &&
      (refAppleReceiptHistoryId.current == null ||
        refAppleReceiptHistoryId.current != appleReceiptHistoryId)
    ) {
      refAppleReceiptHistoryId.current = appleReceiptHistoryId;
      setAccountInfo(null);
      setIsLoading(true);
      SuperUserService.getAppleSubscriptionInfo(appleReceiptHistoryId)
        .then((resp) => {
          setAccountInfo({
            ...resp,
            hasExpired: resp.expires_date
              ? dayjs().isAfter(dayjs(resp.expires_date, APPLE_DATE_FORMAT))
              : false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, appleReceiptHistoryId]);

  const handleChange = (event, tab) => {
    setValue(tab);
  };
  const handleSearchOriginalTransactionId = (originalTransactionId) => {
    SuperUserService.getAppleRefundHistory(originalTransactionId)
      .then((result) => {
        setRefundHistoryDetail(result);
        setModalDetailOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePaymentHistoryTab = () => {
    setIsLoading(true);
    SuperUserService.getAppleHistorySubscription(selectedCompany.id)
      .then((subscription) => {
        setHistorySubscription(subscription);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <AccountInfoStoreDetailModal
        json={refundHistoryDetail}
        open={modalDetailOpen}
        onClose={() => {
          setModalDetailOpen(false);
        }}
      />

      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={value === 0 ? "md" : "xl"}
      >
        <DialogTitle>
          <Typography component="span" variant="h5">
            Informações da conta Apple: - {selectedCompany?.email} -
            appleReceiptHistoryId: {appleReceiptHistoryId}
          </Typography>
          <Tabs onChange={handleChange} value={value}>
            <Tab color="primary" label="Dados da assinatura" />
            <Tab
              color="primary"
              label="Histórico de assinaturas"
              onClick={() => {
                handlePaymentHistoryTab();
              }}
            />
          </Tabs>
          <Divider />
        </DialogTitle>

        <DialogContent>
          {value === 0 ? (
            <>
              <Box sx={{ textAlign: "center" }}>
                {isLoading && <CircularProgress />}
              </Box>
              {!isLoading && accountInfo && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginBottom: "10px" }}
                  >
                    <Chip
                      color="info"
                      label={
                        accountInfo.monthly ? "Plano Mensal" : "Plano Anual"
                      }
                    />
                    <Chip
                      color={accountInfo.hasExpired ? "error" : "success"}
                      label={
                        accountInfo.hasExpired
                          ? "Expirou"
                          : "Está ativo (não expirou)"
                      }
                    />
                    <Chip
                      color={accountInfo.autoRenewing ? "success" : "warning"}
                      label={
                        accountInfo.autoRenewing
                          ? "Está renovando"
                          : "Não está renovando"
                      }
                    />
                  </Box>

                  <Box display="flex" flexDirection="row">
                    <Typography variant="h5">Outras informações:</Typography>
                    <ButtonCopyToClipboard
                      copyValue={accountInfo.receipt}
                      style={{ marginLeft: "5px" }}
                    />
                  </Box>
                  <Typography color="textSecondary">
                    Id do produto:{" "}
                    <Typography component="span">
                      {accountInfo.productId}
                    </Typography>
                  </Typography>
                  <Typography color="textSecondary">
                    Id da Transação:{" "}
                    <Typography component="span">
                      {accountInfo.transaction_id}
                    </Typography>{" "}
                    <ButtonCopyToClipboard
                      copyValue={accountInfo.transaction_id}
                      style={{ marginLeft: "5px" }}
                    />
                  </Typography>
                  <Typography color="textSecondary">
                    Id da Transação Original:{" "}
                    <Typography component="span">
                      {accountInfo.original_transaction_id}
                    </Typography>{" "}
                    <ButtonCopyToClipboard
                      copyValue={accountInfo.original_transaction_id}
                      style={{ marginLeft: "5px" }}
                    />
                    <IconButton
                      sx={{ padding: 0, marginLeft: 1 }}
                      onClick={() => {
                        handleSearchOriginalTransactionId(
                          accountInfo.original_transaction_id
                        );
                      }}
                    >
                      <FontAwesomeIconStatus
                        icon="magnifying-glass"
                        status="primary"
                      />
                    </IconButton>
                  </Typography>
                  <Typography color="textSecondary">
                    Data de criação do recibo:{" "}
                    <Typography component="span">
                      {accountInfo.receipt_creation_date
                        ? dayjs(
                            accountInfo.receipt_creation_date,
                            APPLE_DATE_FORMAT
                          ).format("DD/MM/YYYY HH:mm:ss [UTC]")
                        : "-"}
                    </Typography>
                  </Typography>
                  <Typography color="textSecondary" variant="h5" sx={{ mt: 1 }}>
                    Data de início do periodo:{" "}
                    <Typography component="span" variant="h5">
                      {accountInfo.purchase_date
                        ? dayjs(
                            accountInfo.purchase_date,
                            APPLE_DATE_FORMAT
                          ).format("DD/MM/YYYY HH:mm:ss [UTC]")
                        : "-"}
                    </Typography>
                  </Typography>
                  <Typography color="textSecondary" variant="h5">
                    Data de expiração do periodo:{" "}
                    <Typography component="span" variant="h5">
                      {accountInfo.expires_date
                        ? dayjs(
                            accountInfo.expires_date,
                            APPLE_DATE_FORMAT
                          ).format("DD/MM/YYYY HH:mm:ss [UTC]")
                        : "-"}
                    </Typography>
                  </Typography>
                  <Typography color="textSecondary">
                    Intenção de expiração:{" "}
                    <LabelStatus component="span" status="info" variant="h5">
                      {getExpirationIntent(accountInfo.expirationIntent)}
                    </LabelStatus>
                  </Typography>
                  <Typography color="textSecondary">
                    Renovação automática:{" "}
                    <LabelStatus
                      component="span"
                      status={
                        accountInfo.autoRenewing === true ? "success" : "error"
                      }
                      variant="h5"
                    >
                      {accountInfo.autoRenewing === true ? "Sim" : "Não"}
                    </LabelStatus>
                  </Typography>

                  {accountInfo.cancellation_date && (
                    <>
                      <Divider
                        style={{ marginTop: "5px", marginBottom: "5px" }}
                      />
                      <Typography gutterBottom variant="h5">
                        Reembolsado:
                      </Typography>

                      <Typography color="textSecondary">
                        Data do Cancelamento:{" "}
                        <Typography component="span">
                          {accountInfo.cancellation_date
                            ? dayjs(
                                accountInfo.cancellation_date,
                                APPLE_DATE_FORMAT
                              ).format("DD/MM/YYYY HH:mm:ss [UTC]")
                            : "-"}
                        </Typography>
                      </Typography>
                      <Typography color="textSecondary">
                        Razão do Cancelamento ({accountInfo.cancellation_reason}
                        ):{" "}
                        <Typography component="span">
                          {accountInfo.cancellation_reason === "0" &&
                            "the customer canceled their transaction due to an actual or perceived issue within your app"}
                          {accountInfo.cancellation_reason === "1" &&
                            "the transaction was canceled for another reason; for example, if the customer made the purchase accidentally."}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <AppleAccountInfoStoreTableModal
              historySubscription={historySubscription}
            />
          )}
        </DialogContent>

        <DialogActionsStyled>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          {/* <Button variant="contained" onClick={handleAppleSubscriptionDuplicated} disabled={isLoading}>
            Verificar assinatura duplicada
          </Button> */}
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default AppleAccountInfoModal;
