import {
  AppBar,
  Box,
  Chip,
  darken,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import {
  currentUserSelector,
  resetStore,
  useAppStore,
} from "../../stores/appStore";
import Logo from "./Logo";

const AppBarStyled = styled(AppBar)(() => ({
  background: process.env.REACT_APP_STAGE
    ? `linear-gradient(45deg, rgb(183 26 26) 0%, #e58221 20%, #004FFF  100%)`
    : `linear-gradient(45deg, ${darken(
        "#004FFF",
        0.2
      )} 0%, #004FFF 20%, ${darken("#004FFF", 0.1)}  100%)`,
}));

const isProd =
  process.env.REACT_APP_STAGE == null && process.env.NODE_ENV === "production";

const AppName = styled(Typography)(() => ({
  color: "#ffffffd4",
  padding: "0px 10px",
  marginBottom: "5px",
  fontSize: "20px",
}));

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const currentUser = useAppStore(currentUserSelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBarStyled elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <RouterLink to="/">
          <AppName>MinhaAgenda Turmas BackOffice</AppName>
        </RouterLink>
        {isProd && <Chip label="PROD" color="error" />}

        <Box sx={{ flexGrow: 1 }} />

        <Tooltip title="Opções">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {currentUser?.name[0]}
            </Avatar>
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: "200px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <RouterLink
            to="/app/profile"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            {" "}
            <MenuItem>
              <Avatar /> Perfil{" "}
            </MenuItem>
          </RouterLink>
          <Divider />
          <MenuItem
            onClick={() => {
              resetStore();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBarStyled>
  );
};

export default DashboardNavbar;
