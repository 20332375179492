const Logo = (props) => (
  <img
    alt="MinhaAgenda Turmas BackOffice"
    src="/images/minha-agenda-logo-white-small.png"
    height="35"
    style={{ paddingRight: "10px" }}
    {...props}
  />
);

export default Logo;
