import create from "zustand";
import StorageService from "../services/StorageService";

// SET GLOBAL VARIABLES
const loggedInUserInStorage = StorageService.getCurrentUser();

const useAppStore = create(() => ({
  currentUser: loggedInUserInStorage,
}));

export const setCurrentUser = async (userObject) => {
  useAppStore.setState({ currentUser: userObject });
  StorageService.setCurrentUser(userObject);
};

export const getCurrentUser = () => {
  return useAppStore.getState().currentUser;
};

export const resetStore = () => {
  useAppStore.setState({
    currentUser: null,
  });
  StorageService.removeUser();
};

export const updateTokens = (tokenAndRefreshToken) => {
  const loggedInUser = StorageService.getCurrentUser();

  if (loggedInUser) {
    const newCurrentUser = {
      ...loggedInUser,
      ...tokenAndRefreshToken,
    };
    StorageService.setCurrentUser(newCurrentUser);
    useAppStore.setState({ currentUser: newCurrentUser });
  }
};

export const currentUserSelector = (state) => state.currentUser;
export { useAppStore };
