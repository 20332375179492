import { AppBar, darken, styled, Toolbar, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Logo from "./Logo";

const AppBarStyled = styled(AppBar)(() => ({
  background: process.env.REACT_APP_STAGE
    ? `linear-gradient(45deg, rgb(183 26 26) 0%, #e58221 20%, #004FFF  100%)`
    : `linear-gradient(45deg, ${darken(
        "#004FFF",
        0.2
      )} 0%, #004FFF 20%, ${darken("#004FFF", 0.1)}  100%)`,
}));

const AppName = styled(Typography)(() => ({
  color: "#ffffffd3",
  padding: "0px 10px",
  marginBottom: "5px",
  fontSize: "20px",
  textDecoration: "none",
}));

const MainNavbar = (props) => (
  <AppBarStyled elevation={0} {...props}>
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
      <RouterLink to="/">
        <AppName>MinhaAgenda Turmas BackOffice</AppName>
      </RouterLink>
    </Toolbar>
  </AppBarStyled>
);

export default MainNavbar;
