import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faApple as fabApple,
  faStripe as fabStripe,
  faWhatsapp as fabWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import {
  faAddressCard,
  faAngleDoubleUp,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faArrowUp,
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBell,
  faBirthdayCake,
  faBook,
  faBookOpen,
  faBox,
  faBoxArchive,
  faBriefcase,
  faBuilding,
  faBuildingColumns,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCalendarWeek,
  faCamera,
  faCaretDown,
  faCartArrowDown,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faClipboard,
  faClock,
  faCloud,
  faCoffee,
  faCog,
  faComment,
  faCommentAlt,
  faCopy,
  faCreditCard,
  faCrown,
  faCube,
  faCubes,
  faCut,
  faDesktop,
  faDollarSign,
  faDownload,
  faDumbbell,
  faEnvelope,
  faExclamationTriangle,
  faFile,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileUpload,
  faGlassCheers,
  faGlobe,
  faGripLines,
  faGuitar,
  faHammer,
  faHandHoldingDollar,
  faHandHoldingUsd,
  faHands,
  faHistory,
  faHouse,
  faIdBadge,
  faInfoCircle,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMicrophoneAlt,
  faMinus,
  faMobileAlt,
  faMoneyCheckAlt,
  faPaintBrush,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPercent,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRepeat,
  faRotateLeft,
  faSearch,
  faShare,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faSort,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAlphaUp,
  faSortAmountDown,
  faSortNumericDown,
  faSortNumericDownAlt,
  faSortNumericUp,
  faStethoscope,
  faStickyNote,
  faStopCircle,
  faSun,
  faSyncAlt,
  faSyringe,
  faTableTennis,
  faTag,
  faThumbtack,
  faTimes,
  faToggleOff,
  faToggleOn,
  faTooth,
  faTrash,
  faTrashCan,
  faTrashRestore,
  faUniversity,
  faUpload,
  faUser,
  faUserEdit,
  faUserGear,
  faUserGroup,
  faUsers,
  faUsersCog,
  faUserSecret,
  faUserTie,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircle as farCircle,
  faCircleDot as farCircleDot,
  faPaperPlane as farPaperPlane,
  faSquare as farSquare,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  fabStripe,
  faRotateLeft,
  faUserGear,
  faLockOpen,
  faHistory,
  faUserGroup,
  faFile,
  fabWhatsapp,
  faCheckSquare,
  faCoffee,
  faUser,
  faAngleRight,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faArrowRight,
  faPaintBrush,
  faPlus,
  faCalendar,
  faChevronDown,
  faSearch,
  faClock,
  faPhone,
  faTrash,
  faPen,
  faBars,
  faUsers,
  faCog,
  faBookOpen,
  faSignOutAlt,
  faBriefcase,
  faStickyNote,
  faChartBar,
  faBirthdayCake,
  faHandHoldingUsd,
  faDollarSign,
  faComment,
  faCreditCard,
  faCircle,
  faSort,
  faSortAmountDown,
  faSortAlphaUp,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortNumericDown,
  faSortNumericDownAlt,
  faSortNumericUp,
  faCheck,
  faShieldAlt,
  faChartBar,
  faChartLine,
  faChartPie,
  faQuestionCircle,
  faLock,
  faEnvelope,
  faIdBadge,
  faMobileAlt,
  faCloud,
  faExclamationTriangle,
  faTimes,
  faFileInvoiceDollar,
  faDumbbell,
  faCut,
  faStethoscope,
  faTooth,
  faSyringe,
  faGuitar,
  faMicrophoneAlt,
  faHands,
  faHammer,
  faCamera,
  faVideo,
  faPencilAlt,
  faMoneyCheckAlt,
  faCommentAlt,
  faArrowUp,
  faArrowDown,
  faMinus,
  faInfoCircle,
  faCalendarCheck,
  faCalendarPlus,
  faFileInvoice,
  faBook,
  faCaretDown,
  faUsersCog,
  faUserTie,
  faCalculator,
  faUserEdit,
  faTag,
  faMapMarkerAlt,
  faHistory,
  faGripLines,
  faUniversity,
  faPlusCircle,
  faCrown,
  faShoppingCart,
  faBox,
  faSyncAlt,
  faCube,
  faCubes,
  faCartArrowDown,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faCopy,
  faUserSecret,
  faThumbtack,
  faCheckCircle,
  faStopCircle,
  faPercent,
  faGlobe,
  faSun,
  faGlassCheers,
  faTableTennis,
  faAddressCard,
  faFilePdf,
  farCircle,
  farSquare,
  farPaperPlane,
  faDownload,
  faUpload,
  faFileUpload,
  faAngleDoubleUp,
  faDesktop,
  faBell,
  faPaperPlane,
  faToggleOn,
  faToggleOff,
  faHouse,
  faRepeat,
  faHandHoldingDollar,
  faTrashCan,
  faClipboard,
  faCircleXmark,
  faBan,
  faCheckDouble,
  faArrowsRotate,
  faShare,
  faBoxArchive,
  faBuildingColumns,
  faCircleCheck,
  faChevronUp,
  fabApple,
  faBuilding,
  farCircleDot,
  faTrashRestore,
  faArrowUpRightFromSquare
);
