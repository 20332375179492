import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
} from "@mui/material";
import { Form, Formik } from "formik";
import showNotification from "../../../commons/helpers/showNotification";
import yup from "../../../commons/validators/customYup";
import { FormTextField } from "../../../components/forms/FormikFields";
import SuperUserService from "../../../services/SuperUserService";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));
const DEFAULT_INIT_VALUES = {
  code: "",
};

const schema = yup.object().shape({
  code: yup.string().length(8, "O código de verificação contém 8 digitos"),
});

// COMPONENT
function UserReleaseEmailModal({
  userId,
  modalVisible,
  setModalVisible,
  onComplete,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [foundUserReleaseEmail, setFoundUserReleaseEmail] = useState(false);
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);
      SuperUserService.getReleaseEmail(userId)
        .then((userRelease) => {
          if (userRelease) {
            setInitialValues({
              code: userRelease.code,
            });
            setFoundUserReleaseEmail(true);
          } else {
            setFoundUserReleaseEmail(false);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, userId]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleReleaseEmailSendConfirmationCode = () => {
    const isOk = window.confirm(
      "Deseja realmente enviar o email de confirmação de dono do email?"
    );
    if (isOk) {
      setIsLoading(true);
      SuperUserService.releaseEmailSendConfirmationCode(userId)
        .then((userReleaseEmail) => {
          setFoundUserReleaseEmail(true);
          showNotification(
            "success",
            "Foi enviado o email de confirmação para o usuário."
          );
          setModalVisible(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleReleaseEmailResendConfirmationCode = () => {
    const isOk = window.confirm(
      "Deseja realmente REENVIAR o email de confirmação de dono do email?"
    );
    if (isOk) {
      setIsLoading(true);
      SuperUserService.releaseEmailResendConfirmationCode(userId)
        .then((userReleaseEmail) => {
          setInitialValues({
            code: userReleaseEmail.code,
          });
          setFoundUserReleaseEmail(true);
          showNotification(
            "success",
            "Foi Reenviado o email de confirmação para o usuário."
          );
          setModalVisible(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const myHandleSubmit = (values, actions) => {
    const code = values.code;

    SuperUserService.releaseEmailConfirmCode({
      userId: userId,
      code: code,
    })
      .then((userReleaseEmail) => {
        setInitialValues({
          code: userReleaseEmail.code,
        });
        setFoundUserReleaseEmail(true);
        showNotification(
          "success",
          "Email do usuário foi liberado com sucesso."
        );
        onComplete();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalVisible}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle variant="h5">Liberar Email do Usuário</DialogTitle>
      <Box sx={{ mt: 1 }}>
        {isLoading ? (
          <LinearProgress style={{ width: "100%" }} />
        ) : (
          <div style={{ height: "4px" }} />
        )}
      </Box>
      {!foundUserReleaseEmail ? (
        <>
          <DialogContent dividers>
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                onClick={handleReleaseEmailSendConfirmationCode}
              >
                Enviar código de confirmação para o email
              </Button>
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={myHandleSubmit}
          >
            {({ dirty, isSubmitting, isValid }) => (
              <Form>
                <DialogContent dividers>
                  <Box sx={{ textAlign: "center" }}>
                    <FormTextField
                      label="Informe os 8 digitos do código de verificação"
                      fullWidth
                      name="code"
                    />
                    <Button
                      disabled={isLoading}
                      variant="contained"
                      onClick={() => {
                        handleReleaseEmailResendConfirmationCode();
                      }}
                    >
                      Reenviar Email
                    </Button>
                  </Box>
                </DialogContent>

                <DialogActionsStyled>
                  <Button variant="outlined" onClick={handleClose}>
                    Fechar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!dirty || isSubmitting || !isValid || isLoading}
                  >
                    Liberar Email
                  </Button>
                </DialogActionsStyled>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default UserReleaseEmailModal;
