import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Field, getIn } from "formik";

export const RenderDateTimeSelectField = ({
  field,
  form: { touched, errors, setFieldValue },
  onChangeChecker,
  fullWidth,
  ...others
}) => {
  const touch = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  return (
    <DateTimePicker
      dayOfWeekFormatter={(day) => `${day}.`}
      format="DD/MM/YYYY HH:mm"
      name={field.name}
      value={field.value}
      onChange={(e) => {
        if (e?.isValid()) {
          setFieldValue(field.name, e);
        } else {
          setFieldValue(field.name, null);
        }
      }}
      slotProps={{
        textField: {
          fullWidth: fullWidth,
          error: touch && error != null,
          helperText: touch && error,
          variant: "standard",
          InputLabelProps: { shrink: true },
        },
      }}
      {...others}
    />
  );
};

export const FormDateTimeField = ({ name, ...others }) => (
  <Field name={name} component={RenderDateTimeSelectField} {...others} />
);
