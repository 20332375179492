/* eslint-disable eqeqeq */
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import showNotification from '../../../commons/helpers/showNotification';
import ButtonCopyToClipboard from '../../../components/buttons/ButtonCopyToClipboard';
import { FontAwesomeIconStatus } from '../../../components/icon/FontAwesomeIconStatus';
import LabelStatus from '../../../components/labels/LabelStatus';
import SuperUserService from '../../../services/SuperUserService';
import AndroidAccountInfoStoreTableModal from './AndroidAccountInfoStoreTableModal';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// const MOCK = {
//   productId: 'br.com.minhaagendaapp.android.monthlysubscription',
//   orderId: 'GPA.3348-1298-7084-99134',
//   startTimeMillis: 1621208806497,
//   expiryTimeMillis: 1623894387603,
//   hasExpired: false,
//   autoResumeTimeMillis: null,
//   autoRenewing: true,
//   paymentState: 1,
//   cancelReason: null,
//   userCancellationTimeMillis: null,
//   monthly: true,
// };

function getCancelReason(cancelReasonId) {
  if (cancelReasonId == null) {
    return '-';
  }
  if (cancelReasonId === 0) {
    return 'Assinatura cancelada pelo usuário';
  }
  if (cancelReasonId === 1) {
    return 'Assinatura cancelada pelo sistema';
  }
  if (cancelReasonId === 2) {
    return 'Assinatura substituida por uma nova assinatura';
  }
  if (cancelReasonId === 3) {
    return 'Assinatura cancelada pelo desenvolvedor';
  }
  return 'Não mapeado - informe o suporte ';
}

function getPaymentState(paymentState) {
  if (paymentState == null) {
    return '-';
  }
  if (paymentState === 0) {
    return 'Pagamento pendente';
  }
  if (paymentState === 1) {
    return 'Pagamento recebido';
  }
  if (paymentState === 2) {
    return 'Upgrade / downgrade adiado';
  }
  return 'Não mapeado - informe o suporte ';
}

const STATUS_ACTIVE = 'Active';
const STATUS_CANCELLED = 'Cancelled';
const STATUS_IN_GRACE_PERIOD = 'In grace period';
const STATUS_ON_HOLD = 'On hold';
const STATUS_PAUSED = 'Paused';
const STATUS_EXPIRED = 'Expired';

function getStatusDescription(accountInfo) {
  if (!accountInfo || !accountInfo.expiryTimeMillis) {
    return null;
  }
  const expiryTimeMoment = dayjs(accountInfo.expiryTimeMillis);

  if (
    // future
    expiryTimeMoment.isAfter(dayjs()) &&
    // received
    accountInfo.paymentState === 1 &&
    accountInfo.autoRenewing === true
  ) {
    return STATUS_ACTIVE;
  }

  if (
    // future
    expiryTimeMoment.isAfter(dayjs()) &&
    // received
    accountInfo.paymentState === 1 &&
    accountInfo.autoRenewing === false
  ) {
    return STATUS_CANCELLED;
  }

  if (
    // future
    expiryTimeMoment.isAfter(dayjs()) &&
    // payment pending
    accountInfo.paymentState === 0 &&
    accountInfo.autoRenewing === true
  ) {
    return STATUS_IN_GRACE_PERIOD;
  }

  if (
    // Past
    expiryTimeMoment.isBefore(dayjs()) &&
    // payment pending
    accountInfo.paymentState === 0 &&
    accountInfo.autoRenewing === true
  ) {
    return STATUS_ON_HOLD;
  }

  if (
    // past
    expiryTimeMoment.isBefore(dayjs()) &&
    // received
    accountInfo.paymentState === 1 &&
    accountInfo.autoRenewing === true
  ) {
    return STATUS_PAUSED;
  }

  if (
    // past
    expiryTimeMoment.isBefore(dayjs()) &&
    accountInfo.autoRenewing === false
  ) {
    return STATUS_EXPIRED;
  }

  return null;
}

// COMPONENT
function AndroidAccountInfoModal({ selectedCompany, open, onClose, onDelete }) {
  const refLastAndroidReceiptHistoryId = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [accountInfo, setAccountInfo] = useState();
  const [historySubscription, setHistorySubscription] = useState();
  const [value, setValue] = useState(0);
  // const [androidSubscriptionDuplicatedModalOpen, setAndroidSubscriptionDuplicatedModalOpen] = useState(false);
  // const [duplicateSubscriptions, setDuplicateSubscriptions] = useState();

  const { androidReceiptHistoryId } = selectedCompany;

  useEffect(() => {
    if (
      open &&
      (refLastAndroidReceiptHistoryId.current == null ||
        refLastAndroidReceiptHistoryId.current != androidReceiptHistoryId)
    ) {
      refLastAndroidReceiptHistoryId.current = androidReceiptHistoryId;
      setAccountInfo(null);
      setIsLoading(true);
      SuperUserService.getAndroidSubscriptionInfo(androidReceiptHistoryId)
        .then((resp) => {
          setAccountInfo({
            ...resp,
            hasExpired: resp.expiryTimeMillis ? dayjs().isAfter(dayjs(resp.expiryTimeMillis)) : false,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, androidReceiptHistoryId]);

  const statusDescription = getStatusDescription(accountInfo);
  const accountOnHold = statusDescription === STATUS_ON_HOLD;

  const handleChange = (event, tab) => {
    setValue(tab);
  };

  const handlePaymentHistoryTab = () => {
    setIsLoading(true);
    if (!historySubscription) {
      SuperUserService.getAndroidHistorySubscription(selectedCompany.id)
        .then((subscription) => {
          setHistorySubscription(subscription);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // const handleAndroidSubscriptionDuplicated = () => {
  //   setIsLoading(true);
  //   SuperUserService.getAndroidSubscriptionDuplicated(androidReceiptHistoryId)
  //     .then((companies) => {
  //       setDuplicateSubscriptions(companies.filter((company) => company.id != selectedCompany.id));
  //       setAndroidSubscriptionDuplicatedModalOpen(true);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  return (
    <>
      {/* <SubscriptionDuplicatedModal
        open={androidSubscriptionDuplicatedModalOpen}
        onClose={() => {
          setAndroidSubscriptionDuplicatedModalOpen(false);
        }}
        duplicateSubscriptions={duplicateSubscriptions}
      /> */}
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={value === 0 ? 'md' : 'xl'}
      >
        <DialogTitle>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography component="span" variant="h5">
              Informações da conta Android: - {selectedCompany?.email} - androidReceiptHistoryId:{' '}
              {androidReceiptHistoryId}
            </Typography>
            <IconButton
              onClick={() => {
                const answer = window.confirm(
                  'Você tem certeza que deseja cancelar está assinatura Android?.',
                );
                if (answer) {
                  setIsDeleting(true);
                  SuperUserService.cancelAndroidSubscription(selectedCompany.id)
                    .then(() => {
                      refLastAndroidReceiptHistoryId.current = null;
                      showNotification('success', 'Deletado com successo.');
                      onDelete();
                      onClose();
                    })
                    .finally(() => {
                      setIsDeleting(false);
                    });
                }
              }}
              disabled={isDeleting}
              style={{ marginLeft: '10px' }}
            >
              {isDeleting ? (
                <CircularProgress size={24} />
              ) : (
                <FontAwesomeIconStatus icon="trash" status="error" />
              )}
            </IconButton>
          </Box>
          <Tabs onChange={handleChange} value={value}>
            <Tab color="primary" label="Dados da assinatura" />
            <Tab
              color="primary"
              label="Histórico de assinaturas"
              onClick={() => {
                handlePaymentHistoryTab();
              }}
            />
          </Tabs>
          <Divider />
        </DialogTitle>

        <DialogContent>
          {value === 0 ? (
            <>
              <div style={{ textAlign: 'center' }}>{isLoading && <CircularProgress />}</div>
              {!isLoading && accountInfo && (
                <>
                  {statusDescription && (
                    <Alert
                      severity={STATUS_PAUSED === statusDescription ? 'warning' : 'info'}
                      style={{ marginBottom: '10px' }}
                    >
                      Situação da Assinatura: <b>{statusDescription}</b>
                    </Alert>
                  )}
                  <Box display="flex" flexDirection="row" style={{ marginBottom: '10px' }}>
                    <Chip color="info" label={accountInfo.monthly ? 'Plano Mensal' : 'Plano Anual'} />
                    <Chip
                      color={accountInfo.hasExpired ? 'error' : 'success'}
                      label={accountInfo.hasExpired ? 'Expirou' : 'Está ativo (não expirou)'}
                    />
                    <Chip
                      color={accountInfo.autoRenewing && !accountOnHold ? 'success' : 'warning'}
                      label={
                        accountInfo.autoRenewing
                          ? `Está renovando${accountOnHold ? ' - porém com pendencia de pagamento' : ''}`
                          : 'Não está renovando'
                      }
                    />
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="h5">Outras informações:</Typography>
                    <ButtonCopyToClipboard
                      copyValue={accountInfo.purchaseToken}
                      style={{ marginLeft: '5px' }}
                    />
                  </Box>
                  <Typography color="textSecondary">
                    Id do produto: <Typography component="span">{accountInfo.productId}</Typography>
                    <ButtonCopyToClipboard
                      copyValue={`https://play.google.com/store/account/subscriptions?sku=${accountInfo.productId}&package=com.minhaagenda`}
                      style={{ marginLeft: '5px' }}
                    />
                  </Typography>
                  <Typography color="textSecondary">
                    Id da ordem: <Typography component="span">{accountInfo.orderId}</Typography>{' '}
                    <ButtonCopyToClipboard copyValue={accountInfo.orderId} style={{ marginLeft: '5px' }} />
                  </Typography>
                  <Typography color="textSecondary" sx={{ mt: 1 }} variant="h5">
                    Data de início da compra:{' '}
                    <Typography component="span" variant="h5">
                      {accountInfo.startTimeMillis
                        ? dayjs(accountInfo.startTimeMillis).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </Typography>
                  </Typography>
                  <Typography color="textSecondary" variant="h5">
                    Data de expiração do periodo:{' '}
                    <Typography component="span" variant="h5">
                      {accountInfo.expiryTimeMillis
                        ? dayjs(accountInfo.expiryTimeMillis).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </Typography>
                  </Typography>
                  <Typography color="textSecondary">
                    Tempo de retomada automática:{' '}
                    <LabelStatus
                      component="span"
                      status={accountInfo.autoResumeTimeMillis != null ? 'warning' : null}
                      variant="h5"
                    >
                      {accountInfo.autoResumeTimeMillis
                        ? dayjs(accountInfo.autoResumeTimeMillis).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </LabelStatus>
                  </Typography>
                  <Typography color="textSecondary">
                    Renovação automática:{' '}
                    <LabelStatus
                      component="span"
                      variant="h5"
                      status={accountInfo.autoRenewing === true ? 'success' : 'error'}
                    >
                      {accountInfo.autoRenewing === true ? 'Sim' : 'Não'}
                    </LabelStatus>
                  </Typography>
                  <Typography color="textSecondary">
                    Estado de pagamento:{' '}
                    <LabelStatus component="span" status={accountInfo.paymentState === 1 ? 'success' : null}>
                      {getPaymentState(accountInfo.paymentState)}
                    </LabelStatus>
                  </Typography>
                  <Typography color="textSecondary">
                    Motivo do cancelamento:{' '}
                    <LabelStatus component="span" status="info" variant="h5">
                      {getCancelReason(accountInfo.cancelReason)}
                    </LabelStatus>
                  </Typography>
                  <Typography color="textSecondary">
                    Tempo de cancelamento do usuário:{' '}
                    <Typography component="span">
                      {accountInfo.userCancellationTimeMillis
                        ? dayjs(accountInfo.userCancellationTimeMillis).format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </Typography>
                  </Typography>

                  <Typography color="textSecondary">
                    Linked purchase token:{' '}
                    <Typography component="span">{accountInfo.linkedPurchaseToken ?? '-'}</Typography>{' '}
                    {accountInfo.linkedPurchaseToken && (
                      <ButtonCopyToClipboard
                        copyValue={accountInfo.linkedPurchaseToken}
                        style={{ marginLeft: '5px' }}
                      />
                    )}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <AndroidAccountInfoStoreTableModal historySubscription={historySubscription} />
          )}
        </DialogContent>
        <Divider />
        <DialogActionsStyled>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          {/* <Button variant="contained" onClick={handleAndroidSubscriptionDuplicated} disabled={isLoading}>
            Verificar assinatura duplicada
          </Button> */}
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default AndroidAccountInfoModal;
