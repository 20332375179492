import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import SuperUserService from "../../../services/SuperUserService";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

// COMPONENT
function LoginHistoryModal({ userId, open, onClose }) {
  const [loginHistoryList, setLoginHistoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getLoginHistory(userId)
        .then((resp) => {
          setLoginHistoryList(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, userId]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Typography component="span" variant="h5">
          Histórico de Login
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            {isLoading && <CircularProgress />}
          </Box>

          {!isLoading && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data de Expiração do Token</TableCell>
                  <TableCell>Criado em</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loginHistoryList.map((u) => (
                  <TableRow key={u.id}>
                    <TableCell>
                      {dayjs(u.expiryDate).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      {dayjs(u.createdAt).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </DialogContent>

      <DialogActionsStyled>
        <Button variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
}

export default LoginHistoryModal;
