const ActionButton = {
  BIO: 0,
  AGENDA: 1,
  USUARIOS: 2,
  HISTORICO_LOGIN: 3,
  LOGS_REC_SENHA: 4,
  CREATE_TEMP_SENHA: 5,
  LINK_ATIVACAO: 6,
  LINK_UPGRADE: 7,
  ACCOUNT_DELETE: 8,
  ACCOUNT_RESET: 9,
  SEND_EMAIL: 10,
  EXPEDIENTE: 11,
  EXPORTAR_AGENDA: 12,
  EXPORTAR_AGENDA_COM_PAGAMENTOS: 13,
  IMPORTAR_CLIENTES: 14,
  EXPORTAR_CLIENTES: 15,
  DELETAR_CLIENTES: 16,
  ENVIAR_NOTIFICACAO: 17,
  PAINEL_ASSINATURA: 18,
  MUDAR_VENCIMENTO_STRIPE: 19,
  IMPORTAR_AGENDA: 20,
  LINK_ATIVACAO_ASAAS: 21,
};

export { ActionButton };
