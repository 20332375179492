import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";

import { Form, Formik } from "formik";
import showNotification from "../../../commons/helpers/showNotification";
import PhoneUtils from "../../../commons/utils/phoneUtils";
import yup from "../../../commons/validators/customYup";
import { FormPhoneField } from "../../../components/forms/FormikFields";
import SuperUserService from "../../../services/SuperUserService";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

const schema = yup.object().shape({
  phone: yup.string().max(16),
  phone2: yup.string().max(16),
});

const DEFAULT_INIT_VALUES = {
  phone: "",
};

// COMPONENT
function EditPhoneNumberModal({
  selectedCompany,
  modalVisible,
  setModalVisible,
  onSave,
}) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setInitialValues({
        phone: selectedCompany.phone ?? "",
        timestamp: new Date().getTime(),
      });
    }
  }, [modalVisible, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const updatedPhone =
      values.phone != null && values.phone !== ""
        ? PhoneUtils.sanitizePhoneNumber(values.phone)
        : null;
    SuperUserService.updatePhoneNumber({
      phoneNumber: updatedPhone,
      companyId: selectedCompany.id,
    })
      .then(() => {
        showNotification("success", "Atualizado com successo.");
        onSave({ updatedPhone });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalVisible}
      fullWidth
    >
      <DialogTitle variant="h5">
        Atualizando Telefone da empresa - {selectedCompany.name}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <FormPhoneField name="phone" label="Telefone 1" fullWidth />
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={handleClose}>
                Fechar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default EditPhoneNumberModal;
