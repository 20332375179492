/* eslint-disable no-param-reassign */
//TODO definir icones
const values = [
  { id: 1, name: "Pilates", icon: "paint-brush" },
  { id: 2, name: "Yoga", icon: "hands" },
  { id: 3, name: "Hidro / Natação", icon: "medkit" },
  { id: 4, name: "Artes Marciais", icon: "dumbbell" },
  { id: 5, name: "Musculação / Funcional", icon: "paw" },
  { id: 6, name: "Dança", icon: "baseball-ball" },
  { id: 7, name: "Esportes de quadra / areia", icon: "baseball-ball" },
  { id: 8, name: "Fisioterapia", icon: "baseball-ball" },
  { id: 99, name: "Outros", icon: "briefcase" },
];

const idToValue = values.reduce((obj, item) => {
  obj[item.id] = item;
  return obj;
}, {});

const OccupationAreas = {
  values,
  getById(id) {
    return idToValue[id];
  },
};
export { OccupationAreas };
