import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";

import dayjs from "dayjs";
import { Form, Formik } from "formik";
import showNotification from "../../../commons/helpers/showNotification";
import yup from "../../../commons/validators/customYup";
import {
  FormDateField,
  FormSelect,
} from "../../../components/forms/FormikFields";
import SuperUserService from "../../../services/SuperUserService";
import { FormDateTimeField } from "../../../components/forms/formik/FormDateTimeField";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

const schema = yup.object().shape({
  subscriptionType: yup.string().required(),
});

const DEFAULT_INIT_VALUES = {
  subscriptionType: "",
  lastPaidAt: null,
  subscriptionExpiryDate: null,
};

const SUBSCRIPTION_TYPES = ["ANDROID", "APPLE", "ASAAS"].map((v) => ({
  id: v,
  name: v,
}));

// COMPONENT
function ManageSubscriptionTypeInfoModal({
  selectedCompany,
  open,
  onClose,
  onSave,
}) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (open) {
      setInitialValues({
        lastPaidAt: selectedCompany.lastPaidAt
          ? dayjs(selectedCompany.lastPaidAt, "YYYY-MM-DD HH:mm")
          : null,
        subscriptionExpiryDate: selectedCompany.subscriptionExpiryDate
          ? dayjs(selectedCompany.subscriptionExpiryDate)
          : null,
        subscriptionType: selectedCompany.subscriptionType ?? "",
        timestamp: new Date().getTime(),
      });
    }
  }, [open, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const newValues = {
      lastPaidAt: values.lastPaidAt
        ? values.lastPaidAt.format("YYYY-MM-DD HH:mm")
        : null,
      subscriptionExpiryDate: values.subscriptionExpiryDate
        ? values.subscriptionExpiryDate.format("YYYY-MM-DD")
        : null,
      subscriptionType: values.subscriptionType
        ? values.subscriptionType
        : null,
    };
    SuperUserService.changeSubscriptionType({
      companyId: selectedCompany.id,
      ...newValues,
    })
      .then((companyUpdated) => {
        showNotification("success", "Atualizado com successo.");
        onSave({ ...companyUpdated });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle variant="h5">
        Tipo de Assinatura - {selectedCompany.name}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting, values }) => (
          <Form>
            <DialogContent dividers>
              <div>
                <FormSelect
                  fullWidth
                  label="Tipo de Assinatura"
                  name="subscriptionType"
                  dataSource={SUBSCRIPTION_TYPES}
                />

                <FormDateTimeField
                  fullWidth
                  label="Último pagamento em"
                  name="lastPaidAt"
                />

                {values.subscriptionType === "ASAAS" && (
                  <Box sx={{ mt: 1 }}>
                    <FormDateField
                      fullWidth
                      label="Data de Expiração da Assinatura"
                      name="subscriptionExpiryDate"
                    />
                  </Box>
                )}
              </div>
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!dirty || isSubmitting}
              >
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ManageSubscriptionTypeInfoModal;
