import React from 'react';
import { styled } from '@mui/material/styles';

const ContainerStyled = styled('div')(({ theme, color }) => {
  const themeColor = theme.palette[color];
  return {
    border: 'none',
    cursor: 'default',
    height: '32px',
    display: 'inline-flex',
    outline: '0',
    padding: '0',

    boxSizing: 'border-box',
    transition:
      'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    whiteSpace: 'nowrap',
    borderRadius: '16px',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    paddingLeft: '12px',
    paddingRight: '12px',
    color: color === 'primary' ?? theme.palette.primary.contrastText,
    backgroundColor: color === 'default' ? '#e0e0e0' : themeColor.main,
    marginRight: theme.spacing(1),
  };
});

const ButtonStyled = styled('button')(({ theme, color, disabled, label }) => {
  return {
    backgroundColor: 'transparent !important',
    border: 'none',
    color: color === 'primary' ? 'white' : 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8125rem',
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled && 'none',
    opacity: disabled && '0.5',
  };
});

export default function CustomChip({ label, disabled, onClick, color, className, style, children }) {
  return (
    <ContainerStyled className={className} color={color}>
      <ButtonStyled color={color} disabled={disabled} onClick={onClick} style={style}>
        {label}
      </ButtonStyled>
      {children}
    </ContainerStyled>
  );
}
