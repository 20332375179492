import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import FeatureService from '../../services/FeatureService';
import {
  getPriorityByParam,
  getPriorityTypeColor,
  getStatusByParam,
  getStatusTypeColor,
  MENU_APP,
} from './featureListHelpers';
import showNotification from '../../commons/helpers/showNotification';
import { FontAwesomeIconStatus } from '../../components/icon/FontAwesomeIconStatus';
import ConfirmationDialog from '../../components/modals/ConfirmationDialog';

const DialogActionsStyled = styled(DialogActions)(() => ({ justifyContent: 'space-between' }));

// COMPONENT
function FeaturePreviewModal({ featureForm, open, onClose, handleEdit, onUpdateCompanyCount }) {
  const [featureFormCompaniesReceived, setFeatureFormCompaniesReceived] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState({ open: false, targetObject: null });

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      FeatureService.getCompanyEmailsById(featureForm.id)
        .then((resp) => {
          setFeatureFormCompaniesReceived(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [open, featureForm]);

  const handleDeleteConfirmation = async () => {
    setIsDeleting(true);
    try {
      await FeatureService.deleteCompanyEmails({
        featureRequestId: featureForm.id,
        companyEmailId: modalConfirmDelete.targetObject.id,
      });
      const newDataSource = featureFormCompaniesReceived.filter(
        (data) => data.id !== modalConfirmDelete.targetObject.id,
      );
      setFeatureFormCompaniesReceived(newDataSource);
      onUpdateCompanyCount(newDataSource.length, featureForm);
      showNotification('success', 'Deletado com sucesso');
    } finally {
      setModalConfirmDelete((prev) => ({ ...prev, open: false }));
      setIsDeleting(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        title="Exclusão"
        message="Você tem certeza que deseja deletar?"
        onConfirm={handleDeleteConfirmation}
        open={modalConfirmDelete.open}
        onClose={() => {
          setModalConfirmDelete((prev) => ({ ...prev, open: false }));
        }}
      />

      <Dialog maxWidth="lg" onClose={onClose} open={open}>
        {featureFormCompaniesReceived && (
          <DialogTitle>
            <Typography component="span" variant="h5">
              {featureForm && featureForm.name}
            </Typography>
          </DialogTitle>
        )}

        {!isLoading && featureForm && (
          <DialogContent sx={{ p: 0.5, minWidth: '600px' }} dividers>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center' }}>{isLoading && <CircularProgress />}</Box>

              <Table>
                <TableBody>
                  <TableRow key={`preview.status.${featureForm.status}`}>
                    <TableCell size="small">
                      <Box sx={{ mb: 1 }}>
                        <Box component="span">Status:</Box>
                        <Chip
                          sx={{ ml: 1.5 }}
                          size="small"
                          color={getStatusTypeColor(featureForm.status)}
                          label={getStatusByParam(featureForm.status)}
                        />
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Box component="span">Prioridade:</Box>
                        <Chip
                          sx={{ ml: 1.5 }}
                          size="small"
                          color={getPriorityTypeColor(featureForm.priority)}
                          label={getPriorityByParam(featureForm.priority)}
                        />
                      </Box>
                      <Box sx={{ mb: 1 }}>
                        <Box component="span">
                          Menu: {featureForm.menu ? MENU_APP[featureForm.menu].name : ''}
                        </Box>
                      </Box>
                      <Box component="span">Descrição: {featureForm.description}</Box>
                    </TableCell>
                  </TableRow>
                  {featureForm.countCompanies > 0 && (
                    <TableRow key={`requesters`}>
                      <TableCell size="small" sx={{ borderBottom: 'none' }}>
                        <Typography variant="h5">Solicitantes</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {featureFormCompaniesReceived?.map((featureCompanyEmail, i) => {
                    return (
                      <TableRow key={featureCompanyEmail.id}>
                        <TableCell size="small" sx={{ borderBottom: 'none' }}>
                          <span>{featureCompanyEmail.companyEmail} </span>

                          <IconButton
                            onClick={() => {
                              setModalConfirmDelete({ open: true, targetObject: featureCompanyEmail });
                            }}
                            style={{ marginLeft: '10px' }}
                            disabled={isDeleting}
                          >
                            <FontAwesomeIconStatus status="error" icon="trash" size="sm" />
                            {isDeleting && <CircularProgress size={15} />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </DialogContent>
        )}
        <DialogActionsStyled>
          <Button variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Box>
            <Button
              color="primary"
              onClick={() => handleEdit(featureForm)}
              disabled={isLoading || isDeleting}
              variant="contained"
            >
              Editar
            </Button>
          </Box>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
}

export default FeaturePreviewModal;
