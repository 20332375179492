import { Box, CircularProgress, Typography } from "@mui/material";

function AppLoadingIndicator() {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <CircularProgress
        size={50}
        thickness={4}
        value={100}
        sx={{ marginTop: "20vh" }}
      />
      <Typography
        gutterBottom
        sx={{ color: "primary.main", marginTop: "1rem" }}
      >
        Carregando MinhaAgenda Turmas BackOffice...
      </Typography>
    </Box>
  );
}

export default AppLoadingIndicator;
