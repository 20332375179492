import { ROLES_ADMIN } from "../commons/constants";

const menuItems = [
  {
    href: "/app/home-page",
    title: "Home Page",
    iconName: "house",
  },
  {
    href: "/app/manage-company",
    title: "Empresas",
    iconName: "building",
    requiredRoles: ROLES_ADMIN,
    subItems: [
      {
        href: "/app/manage-company",
        title: "Gerenciar Empresa",
        iconName: "user-gear",
        requiredRoles: ROLES_ADMIN,
      },
      {
        href: "/app/manage-company/list-users",
        title: "Pesquisa Aberta",
        iconName: "search",
        requiredRoles: ROLES_ADMIN,
      },
    ],
  },
  {
    href: "/app/feature",
    title: "Features",
    iconName: "plus",
  },
  {
    href: "/app/employee",
    title: "Funcionários",
    iconName: "users-gear",
    requiredRoles: ROLES_ADMIN,
  },
];

export default menuItems.filter((p) => {
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv !== "development") {
    return !p.isDevelopmentOnly;
  }
  return true;
});
