import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
} from "@mui/material";
import yup from "../../../commons/validators/customYup";
import SuperUserService from "../../../services/SuperUserService";
import showNotification from "../../../commons/helpers/showNotification";
import {
  FormCheckbox,
  FormSelect,
} from "../../../components/forms/FormikFields";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

const schema = yup.object().shape({
  notificationType: yup.string().required(),
});

const NOTIFICATION_TYPES = ["ACCOUNT_STATUS_UPDATED"].map((type) => ({
  id: type,
  name: type,
}));

const DEFAULT_INIT_VALUES = {
  notificationType: "",
  silent: true,
};

// COMPONENT
function PushNotificationForCompanyModal({
  selectedCompany,
  modalVisible,
  setModalVisible,
}) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);

  useEffect(() => {
    if (modalVisible) {
      setInitialValues({
        notificationType: "",
        silent: true,
        timestamp: new Date().getTime(),
      });
    }
  }, [modalVisible, selectedCompany]);

  const myHandleSubmit = (values, actions) => {
    const answer = window.confirm(
      "Você tem certeza que deseja enviar a notificação remota?"
    );
    if (answer) {
      SuperUserService.sendPushNotification({
        companyId: selectedCompany.id,
        notificationType: values.notificationType,
        silent: values.silent,
      })
        .then(() => {
          showNotification("success", "Enviado com successo.");
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* DIALOG */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalVisible}
        fullWidth
      >
        <DialogTitle variant="h5">Enviar notificação remota</DialogTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={myHandleSubmit}
        >
          {({ dirty, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <div>
                  <FormCheckbox label="Silenciosa" name="silent" />
                  <FormSelect
                    fullWidth
                    label="Notification Type"
                    name="notificationType"
                    dataSource={NOTIFICATION_TYPES}
                  />
                </div>
              </DialogContent>

              <DialogActionsStyled>
                <Button variant="outlined" onClick={handleClose}>
                  Fechar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!dirty || isSubmitting}
                >
                  Enviar
                </Button>
              </DialogActionsStyled>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default PushNotificationForCompanyModal;
