import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Tooltip } from "@mui/material";
import FontAwesomeDoubleIconStatus from "../../components/icon/FontAwesomeDoubleIconStatus";

const ActionButton = {
  USUARIOS: 2,
  HISTORICO_LOGIN: 3,
  LOGS_REC_SENHA: 4,
  CREATE_TEMP_SENHA: 5,
  LINK_ATIVACAO: 6,
  LINK_UPGRADE: 7,
  ACCOUNT_DELETE: 8,
  ACCOUNT_RESET: 9,
  LINK_ATIVACAO_ASAAS: 21,
  ENVIAR_NOTIFICACAO: 17,
};

function TopMainButtons({ onClickButton }) {
  const buttons = [
    {
      type: ActionButton.USUARIOS,
      title: "Usuários",
      iconName: "user-group",
      iconColor: "primary",
      toolTipMessage: "Lista de usuários",
      disabled: false,
    },
    {
      type: ActionButton.HISTORICO_LOGIN,
      title: "Histórico de Login",
      iconName: "history",
      iconColor: "primary",
      disabled: false,
    },
    {
      type: ActionButton.CREATE_TEMP_SENHA,
      title: "Senha Temporária",
      modalConfirmMessage:
        "Você tem certeza que deseja criar uma nova senha temporária?",
      iconName: "lock",
      iconColor: "primary",
      disabled: false,
    },
    {
      type: ActionButton.LINK_ATIVACAO_ASAAS,
      title: "Ative Asaas",
      modalConfirmMessage:
        "Você tem certeza que deseja gerar o link de ativação ASAAS? Ele dura 21 dias e se vc ja tiver gerado outro para este usuario, o link antigo ira se invalidar.",
      iconName: "crown",
      iconColor: "info",
      toolTipMessage: "Gerar link de ativação",
      disabled: false,
    },
    {
      type: ActionButton.ENVIAR_NOTIFICACAO,
      title: "Enviar notificação",
      iconName: "paper-plane",
      iconColor: "primary",
      disabled: false,
    },
  ];

  return (
    <Grid container rowGap={1}>
      {buttons.map((button, index) => (
        <Tooltip
          key={index}
          title={button.toolTipMessage ? button.toolTipMessage : ""}
          disableHoverListener={button.toolTipMessage ? false : true}
        >
          <Button
            variant="outlined"
            sx={{ mr: 1, mb: 1 }}
            onClick={() => onClickButton(button)}
            disabled={button.disabled}
            startIcon={
              button.secondIconName ? (
                <FontAwesomeDoubleIconStatus
                  icon1={button.iconName}
                  status={button.iconColor}
                />
              ) : (
                <FontAwesomeIcon
                  icon={button.iconName}
                  color={button.iconColor}
                />
              )
            }
            color={button.iconColor}
          >
            {button.title}
          </Button>
        </Tooltip>
      ))}
    </Grid>
  );
}

export { TopMainButtons, ActionButton };
