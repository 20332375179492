import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import showNotification from "../../../commons/helpers/showNotification";
import NumberUtils from "../../../commons/utils/numberUtils";
import yup from "../../../commons/validators/customYup";
import {
  FormDateField,
  FormNumberFormatField,
  FormTextField,
} from "../../../components/forms/FormikFields";
import SuperUserService from "../../../services/SuperUserService";

const DialogActionsStyled = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}));

const ToggleTextStyled = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: "capitalize",
}));

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    color: "rgb(250, 250, 253)",
    backgroundColor: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  dueDate: yup.date().nullable().required(),
  planId: yup.number().required(),
  priceOverride: yup.number(),
  descriptionSuffix: yup.string(),
});

const DEFAULT_INIT_VALUES = {
  dueDate: null,
  planId: "",
  priceOverride: "",
  descriptionSuffix: "",
};

// COMPONENT
function AsaasCreateChargeModal({ selectedCompany, open, onClose, onSave }) {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT_VALUES);
  const [asaasPlans, setAsaasPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { maxNumberOfUsers } = selectedCompany;

  useEffect(() => {
    if (open) {
      setInitialValues({
        dueDate: dayjs(),
        planId: "",
        timestamp: new Date().getTime(),
        priceOverride: "",
        descriptionSuffix: "(Avulsa)",
      });
    }
  }, [open, selectedCompany]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      SuperUserService.getAsaasPlan(maxNumberOfUsers)
        .then((respPlan) => {
          setAsaasPlans(respPlan);
        })
        .finally(() => {});
      setIsLoading(false);
    }
  }, [open, maxNumberOfUsers]);

  const myHandleSubmit = (values, actions) => {
    const answer = window.confirm(
      "Você tem certeza que deseja criar uma COBRANÇA AVULSA?"
    );
    if (answer) {
      SuperUserService.createAsaasCharge({
        dueDate: values.dueDate.format("YYYY-MM-DD"),
        planId: values.planId,
        priceOverride: values.priceOverride,
        descriptionSuffix: values.descriptionSuffix,
        selectedCompanyId: selectedCompany.id,
      })
        .then(() => {
          showNotification("success", "Cobrança criada com successo.");
          onSave();
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="span" variant="h5">
            Criando cobrança avulsa - {selectedCompany.name}
          </Typography>
        </Box>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={myHandleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <DialogContent dividers>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                {isLoading && <CircularProgress size={24} />}
                {!isLoading && asaasPlans && (
                  <ToggleButtonGroupStyled
                    size="small"
                    value={`${values.planId}`}
                    onChange={(evt, newValue) => {
                      if (newValue != null) {
                        setFieldValue("planId", newValue);
                      }
                    }}
                    exclusive
                  >
                    {asaasPlans.map((plan) => (
                      <ToggleButtonStyled key={plan.id} value={`${plan.id}`}>
                        <ToggleTextStyled>
                          {plan.name} - {NumberUtils.toCurrency(plan.price, 2)}
                        </ToggleTextStyled>
                      </ToggleButtonStyled>
                    ))}
                  </ToggleButtonGroupStyled>
                )}
                {!isLoading && asaasPlans && asaasPlans.length === 0 && (
                  <Typography variant="h5">Nenhum plano encontrado</Typography>
                )}
                <Typography color="error">{errors?.planId}</Typography>
              </Box>

              <Box>
                <FormDateField
                  fullWidth
                  label="Data do vencimento"
                  name="dueDate"
                />
                <FormNumberFormatField
                  fullWidth
                  label="Preço alternativo (Deixe em branco para usar o valor do plano)"
                  name="priceOverride"
                />
                <FormTextField
                  fullWidth
                  label="Sufixo da descrição"
                  name="descriptionSuffix"
                />
              </Box>
            </DialogContent>

            <DialogActionsStyled>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                Salvar
              </Button>
            </DialogActionsStyled>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AsaasCreateChargeModal;
